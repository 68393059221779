.header {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header h4 {
 margin-left: 10px;
}

.remotesignalimage {
  width: 15px;
  pointer-events: none;
}

.main {
  display: flex;
justify-content: center;
align-items: center;
padding-top: 10px;
}
.content_bg {
  background-image: url('./images/giglo_remote_body.png');
  width: 270px;
  height: 638px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  position: relative;
}
.signal {
  width: 270px;
  height: 30px;
  padding-top: 65px;
}
.signalimage {
  margin-left:0px;
  width: 30px;
}
.buttondiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  height: 270px;
  margin-top: 35px;
}
.speedUpimage {
  margin-left: 0px;
  width: 120px;
}
.startStopimage {
  margin-left: 0px;
 margin-top: -28px;
 width: 85px;
}
.speedDownimage {
  margin-left: 0px;
  margin-top: -28px;
  width: 120px;
}
.plusMinusdiv {
  width: 270px;
  height: 80px;
  margin-top: 21px;
}
.Minusdiv {
  float: left;
  width: 50%;
}
.plusdiv {
  float: left;
  width: 50%;
}

.MinusImage {
  margin-left: 40px;
  width: 50px;
}
.PlusImage {
  margin-right: 40px;
  width: 50px;
}
.logodiv {
  width: 270px;
  height: 80px;
  padding-top: 40px;
}
.logoImage {
  margin-left: 0px;
  width: 200px;
}